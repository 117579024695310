* {
  box-sizing: border-box;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: #f9f9f9;
}

body {
  margin: 0;
  font-family: "Town", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Hack fixing hidden ActionMenu in Dialog */
#__primerPortalRoot__ {
  z-index: 9999;
}

/* Hide Edge little password eye*/
input::-ms-reveal,
input::-ms-clear {
  display: none;
}

@keyframes modal {
  from  {bottom: 0; opacity: 0;}
  to  {bottom: 75; opacity: 1;}
}

@font-face {
  font-family: 'Town';
  src: local('Town'), url(../../public/fonts/town-80-text-medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Town10Display-Black';
  src: local('Town10Display-Black'), url(../../public/fonts/town-10-display-black.otf) format('truetype');
}